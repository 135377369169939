.upcoming-project {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 60px;
    padding: 60px;
}

.upcoming-project-section{
    margin: 60px;
    padding: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 50px;
    justify-content: space-evenly;
}
.card-text{
   flex-grow:1;
}
