.hero-container {
    max-height: 100%;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.hero-image {
    max-width: 100%;
    height: 100%;
    opacity: 0.4;
    padding: 0;
    margin: 0;
}

.overlay-donation-hero-text {
    position: absolute;
    width: 100%;
    height: 100%;
    letter-spacing: 2px;
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: white;
    font-weight: bold;
}

.overlay-text-p {
    font-size: 2rem;
    letter-spacing: 1px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: white;
    font-weight: bold;
}

.ok {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 5px;
    color: white;
    font-weight: bold;
}




@media (max-width: 600px) {
    .inner {
        margin-top: 100px;
    }
    .ok  {
        font-size: 2rem;
        /* Adjust the font size for mobile screens */
    }

    .overlay-text-p  {
        font-size: 1.4rem; /* Adjust the font size for mobile screens */
        padding: 8px;
        letter-spacing: 1px;
    }
}

