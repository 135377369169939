.deleteIcon{
    color: red;
    font-size: 30px;
    margin: 7px;
}

.editBtn, .deleteBtn{
    border: none;
    background-color: transparent;
}

.btnDisplay{
    display: flex;
    border: none;
    justify-content: center;
}

.penIcon{
    color: green;
    font-size: 30px;
    margin: 7px;
}