
.video-container {
    position: relative;
  }
  
  .back-video {
    width: 100%;
    height: 100%; 
    object-fit: cover;
    position: relative;
    opacity: 0.8;
    z-index: 0;
  }

  
 .overlay {
     position: absolute;
     color: white;
     width:100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.2);
     bottom:0;
 }


 .inner {
 
     margin-top: 300px;
 
 }
 
 .inner h1{
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  text-shadow: black 1px 1px 1px;
  color: white;
    text-align: center;
 }
 .inner p{
  font-size: 1.3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  text-shadow: black 1px 1px 1px;
  color: white;
    text-align: center;

}
 

 /* Media query for mobile screens */
   @media (max-width: 768px) {

    .inner {

        margin-top: 100px;
    }
      .inner h1 {
        font-size: 2rem;

       /* Adjust the font size for mobile screens */
     }

      .inner p {
        font-size: 1.5rem; /* Adjust the font size for mobile screens */
    }
}


@media (max-width: 425px) {

  .inner {
    margin-top: 70px; 
  }

  .inner h1 {
    font-size: 1.8rem;
  }

  .inner p {
    font-size: 1.2rem; 
}
}