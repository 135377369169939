.logo{
    width: 190px;
    margin-top: -17px;
}
.nav{
    background-color: #2B2B2B !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5), 0px -5px 10px rgba(0, 0, 0, 0.5);
    padding: 30px;
    z-index: 9999;
    width: 100%;
}

.nav-link{
    color:#F4F3EE;
    font-family: 'Montserrat', sans-serif;
    border-bottom: transparent solid 3px;
    font-size: 1.2rem;
    text-transform: capitalize;
}
.navbar-toggler {
    background-color: #eae8c8;
}

.nav-link:hover{
    color:white;
    transition: ease 0.2s;
    border-bottom:#eae8c8 solid 2px;
    border-radius: 1px;
}


@media only screen and (max-width: 400px) {
    body {
        background-color: lightblue;
    }
}

/*@media only screen and (max-width: 750px) {
    nav  ::after, ::before {
        box-sizing: unset;
    }
}*/


