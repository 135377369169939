.carousel-inner {
    max-height: 35rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}


.carousel-caption p {
    margin-bottom: 68% !important;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
    text-shadow: black 1px 1px 1px;
    color: white;
}

.carousel-inner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1; 
}

.carousel-caption{
    padding: 10px; 
    z-index: 2;
}

@media (min-width: 1600px) {
    .carousel-caption p{
        margin-bottom: 72% !important;
    }
  }
  
@media (max-width: 1000px){
    .carousel-caption p{
        font-size: 1.3rem;

    }
    .carousel-caption p {
        margin-bottom: 40% !important;
    }
}