.section__padding{
    padding: 4rem 4rem;
}

.footer{
    background-color: #2B2B2B;
}

.sb__footer{
    display: flex;
    flex-direction: column;
}

.sb__footer-links{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
}

.sb__footer-links_div{
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content:flex-start;
    flex-direction: column;
    color: white;
}

a{
    color: white;
    text-decoration: none;
}

.socialmedia{
    display: flex;
    flex-direction: row;
}

.socialmedia img{
    width: 80%;
}

.sb__footer-links_div h4{
    font-size: 18px;
    line-height: 17px;
    margin-bottom: 0.9rem;

}

/*Partners*/
.sb__footer-links_div p {
    font-size: 14px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
}

.sb__footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb__footer-below-links{
    display: flex;
    flex-direction: row;
}

.sb__footer-below-links p{
    font-size: 14px;
    line-height: 15px;
    margin-left: 2rem;
    color: white;
    font-weight: 600;
}

hr{
    color: white !important;
    width: 100%;
}

/* Puedo eliminar para quitar el center del footer*/
.sb__footer-below{
    display: flex;
    justify-content: center;
}

.sb__footer-copyright p{
    font-size: 14px;
    line-height: 15px;
    color:white ;
    font-weight: 600;
}


@media screen and (max-width: 850px){
    .sb__footer-heading h1{
        font-size: 40px;
    }
}

@media screen and (max-width: 550px){
    .sb__footer-heading h1{
        font-size: 38px;
        line-height: 42px;
    }
    .sb_footer-links div{
        margin: 1rem 0;
    }

    .sb__footer-btn p{
        font-size: 18px;
        line-height: 20px;
    }

    .sb__footer-below{
        flex-direction: column;
        justify-content: left;
    }
    .sb__footer-below-links{
        flex-direction: column;
    }

    .sb__footer-below-links p{
        margin-left: 7rem; /*aqui cambie margins para centrar*/
        margin-top: 1rem;
    }
    .sb__footer-copyright p {
        margin-left: 7rem;/*aqui cambie margins para centrar*/
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px){
    .sb__footer-heading h1{
        font-size: 31px;
        line-height: 34px;
    }


    .sb__footer-below-links p{
        margin-left: 5rem;/*aqui cambie margins para centrar*/
        margin-top: 1rem;

    }
    .sb__footer-copyright p {
        margin-left: 5rem;/*aqui cambie margins para centrar*/
        margin-top: 1rem;
        flex-direction: column;
        justify-content: left;
    }
}

@media screen and (max-width: 220px){
    .sb__footer-heading h1{
        font-size: 31px;
        line-height: 34px;
    }

    .sb__footer-below-links p{
        margin-left: 1rem;/*aqui cambie margins para centrar*/
        margin-top: 1rem;

    }
    .sb__footer-copyright p {
        margin-left: 1rem;/*aqui cambie margins para centrar*/
        margin-top: 1rem;
        flex-direction: column;
        justify-content: left;
    }
}



