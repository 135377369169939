
.Mission-and-Goals {

    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 15vw;
    padding-top: 0;

}

.Mission {
    background-color: white;
    padding: 43px;
    max-width: 70%;
}

.Mission p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.mission-image img {
    width: 400px;
    height: 400px;
    border-radius: 100%;
    object-fit: cover;
    transition: transform 0.3s;
    box-shadow: black 2px 2px 5px;
}

.mission-image img:hover {

    transform: scale(1.1);

}


.missionTitle {
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    text-shadow: none;
    max-width: 50%;
    text-align: center;
    margin: auto;
    margin-top: 12vw;
    transition: ease-in-out 1s;
    font-size: 3rem;
}

.missionTitle:hover {
    transform: scale(1.1);
}

.main-goal-box {
    padding: 12vw;
}


.Goals {
    display: flex;
    width: 100%;
    gap: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
    padding-top: 0;
    padding: 3vw;
}

.Goals p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: right;
}

.Goals img {
    width: 400px;
    height: 400px;
    border-radius: 100%;
    object-fit: cover;
    transition: transform 0.3s;
    box-shadow: black 2px 2px 5px;
}

.goalsTitle {
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    text-shadow: none;
    max-width: 50%;
    text-align: center;
    margin-bottom: 6vw;
    margin: auto;
    transition: ease-in-out 1s;
    font-size: 3rem;
}

.goalsTitle:hover {
    transform: scale(1.1);
}

@media (max-width: 1000px) {
    .Mission-and-Goals {
        flex-direction: column;
        padding: 0vw;
    }

    .Mission {
        background-color: white;
        padding: 0;
        max-width: 80%;
        text-align: justify;
    }

    .missionTitle {
        max-width: 50%;
        margin: auto;
        margin-top: 12vw;
        transition: ease-in-out 1s;
        font-size: 2rem !important;
        margin-bottom: 6vw;
    }

    .mission-image img {
        width: 250px;
        height: 250px;
        margin-bottom: 12vw;
    }

    .Goals img {
        width: 250px;
        height: 250px;
    }

    .Goals {
        display: flex;
        flex-direction: column;
    }

    .goalsTitle {
        max-width: 50%;
        margin: auto;
        margin-top: 12vw;
        transition: ease-in-out 1s;
        font-size: 2rem !important;
        margin-bottom: 6vw;
    }

    .Goals p {
        text-align: center;
    }
}