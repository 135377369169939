.animated-count-section {
    padding-top: 12vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: center;
    width: 100%;
   /* background-color: #f7f5d1;*/
    padding: 20px;
}

.animated-count {
    border: 1px solid #2b2b2b;
    width: 300px;
    background-color: #2b2b2b;
    color: white;
    padding: 50px;
    text-align: center;
    align-items: center;
}