/*new form css*/

.error {
    color: red;
    padding: 5px;
    margin: 3px;
}


.form-body {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    align-items: center;
    background-color: #2b2b2b;
    margin: auto;
    padding: 50px;
}

.form-box {
    border-radius: 25px;
    max-width: 100%;
}


.tarjeta:hover {
    cursor: pointer;
}

.contact-form-main {
    display: grid;
    max-width: 100%;
    max-height: 100%;
    padding: 40px;
    background-color: #2b2b2b;
    align-items: center;
}

.textarea.form-control {
    min-height: calc(1.5em + (0.75rem + 2px));
}

.form-control {
    width: 350px !important;
    height: 60px;
}

label {
    display: block;
    margin-bottom:10px;
    color: gray;
}

.contact-form-input-group {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    gap: 30px;
    margin: auto;
}



@media only screen and (max-width: 750px) {
    label{
        display: grid;
        justify-content: space-evenly;
        margin: 20px;
    }
    .form-box {
        border: 1px solid #dcdbd9;
        margin: auto;
        max-width: 600px;
        max-height: 100%;
        padding: 40px;
        background-color: #2b2b2b;
        align-items: center;
    }

    .form-body {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        align-items: start;
        background-color: #2b2b2b;
        margin: auto;
        padding: 20px 50px
    }

    .form-control {
        width: 350px;
        height: 60px;
        align-items: start;
    }

    .donor-detail {
        flex-direction: column;
    }

    .contact-form-control {
        align-items: center;
    }
}



@media only screen and (max-width: 850px) {
    label{
        display: grid;
        justify-content: space-evenly;
        margin: 20px;
    }
    .form-box {
        border: 1px solid #dcdbd9;
        margin: auto;
        max-width: 600px;
        max-height: 100%;
        padding: 40px;
        background-color: #2b2b2b;
        align-items: center;
    }
    .form-body {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        align-items: start;
        background-color: #2b2b2b;
        margin: auto;
        padding: 20px 50px
    }
    .form-control {
        width: 350px;
        height: 60px;
        align-items: start;
    }
    .donor-detail {
        flex-direction: column;
    }

    .contact-form-control {
        align-items: center;
    }
}
/*test*/


