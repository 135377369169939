.History-and-Place-sr {

  display: flex;
  width: 100%;
  gap: 2px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: black;
  padding:1vw;
  padding-top: 0;
  
}
.History-sr {
  font-family: 'Montserrat', sans-serif;
  background-color :white;
     padding:43px;
     max-width: 70%;
}


.soulplates-title-sr{
  text-align: center;
    font-size: 3rem;
    font-weight: 500;
    color: black;
    padding: 2vw;
}
