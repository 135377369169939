.editButton{
    margin-left: 650px;
    margin-top: 0px;
    margin-bottom: 30px;
    width: 80px;
    height: 40px;
    border-radius: 8px;
    background-color:   #6babb7;
    color: white;
    border: none;
}

.users{
    padding: 12vw;
    padding-top: 6vw;
}

.beneficiariesTable, td{
    border: 1px black solid;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

th{
    background-color: #F7F5D2 !important;
}

.tableTitle{
        font-family: 'Montserrat', sans-serif;
        text-shadow: none;
        max-width: 50%;
        text-align: center;
        margin: auto;
        transition: ease-in-out 1s;
        font-size: 3rem;
        margin-bottom: 6vw;
      }