.container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    max-width: 100%;
    margin-bottom: 0;
    padding: 20px;
    background-color: #F7F5D2;
}

.title {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.subtitle {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.title {
    text-align: center;
    font-size: 3rem;
    font-weight: 500;
    color: black;
    padding: 2vw;
}

.sectionTitle {
    margin: auto;
    align-items: center;
    text-align: center;
}

.donation-form {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin: auto;
    /*  background-color: #F7F5D2;*/

}

.donation-amount {
    /*width: 50%;*/
    /*display: grid;*/
    /*margin-bottom: 30px;*/
    display: flex;
}

.sol {
    float: left;
    margin-right: 50px;
}

.donor-detail {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
}

.donations {
    height: 60px !important;
    padding: 8px 15px;
    border-radius: 0;
    border: 2px solid #efefef;
    width: 100%;
    color: #6e6e6e;
    background-color: #fff;
    letter-spacing: 0.1px;
    font-size: 14px;
    appearance: auto;

}

.donationsBtn {
    margin-bottom: 10px;
    background-color: #6babb7;
    color: white;
    font-weight: 600 !important;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: ease-in-out 0.5s;
    width: 20%;
}

/*  .donationsBtn:hover{
    background-color: #eae8c8
}*/
.payment-method-align-items-center {
    /*display: grid;*/
    /*width: 50%;*/
    /*align-items: center;*/
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    /*justify-content: space-between;*/
    row-gap: 2rem;
}

.currency {
    padding: 16px
}

.radio {
    cursor: pointer;
    position: relative;
    font-weight: 400;
}

.textarea.form-control {
    height: auto;
}

.textarea.form-control {
    min-height: calc(1.5em + (0.75rem + 2px));
}

/*li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 0;
}*/

.unOrderList {
    display: flex;
    float: left;
    align-items: center;
    gap: 40px;
    padding: 0;
}

.payment-methode-option {
    display: flex;
    gap: 1rem;
}

.donation-form-body {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
}

.campaign {
    display: flex;
    justify-content: space-between;
    /*text-align: center;*/
}

.donation-option-title {
    text-align: center;
}

.donation-amount-title {
    text-align: left;
}

.donor-details {
    flex-direction: column;
    display: flex;
    row-gap: 2rem;
    flex: 1 1 0%;
}

.donor-input {
    display: flex;
    column-gap: 1rem;
}

.general {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    margin: auto;
}


/*ul {
    margin-bottom: 0px;
}*/


.label {
    display: flex;
    column-gap: 0.5rem;
}

.donation-campaign {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}


.btn-money-selection {
    width: 60px;
    border: 1px solid #F7F5D2;
    background-color: #F7F5D2;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


@media only screen and (max-width: 700px) {
    .donor-input {
        flex-wrap: wrap;
        gap: 30px;

    }
}