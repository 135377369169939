.aboutus-container {
    background-color: white;
    padding-left: 16vw;
    padding-right: 16vw;
    padding-top: 5vw;
    padding-bottom: 4vw;


}

.icon-block-personell {
  text-align: center;
}

.aboutus-card {

    height: 350px;
    width: 300px;

}

.col-md-4.aboutus {
    margin-bottom: 20px;
}


.card.profile-card-1.aboutus-card {
    position: relative;
}


.background-img-about {
    width: 100%;
    height: 300px;
    border-radius: 5px;
}

.profile-img-about {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 140px;
    border: 3px solid #fff;
    border-radius: 50%;
    z-index: 1;

}


.card-body.aboutus-body {
    text-align: center;
    padding: 20px;
}


.card-title.aboutus-title,
.card-text.aboutus-text {
    margin: 10px 0;
}


.icon-block-personell {
    text-align: center;
    margin-top: 10px;
}


.icon-block-personell a {
    color: #000;
    font-size: 20px;
    margin: 0 10px;
    text-decoration: none;
}


.icon-block-personell a:hover {
    color: #007bff;
}

@media (max-width: 768px) {
    .aboutus-card {
        height: 300px;
        width: 240px;
        background-color: #fff;
    }

    .background-img-about {
        height: 250px; /* Adjust the height for smaller screens */
    }

    .profile-img-about {
        width: 100px; /* Further adjust the width for smaller screens */
        height: 100px; /* Further adjust the height for smaller screens */
        margin: 10px auto; /* Center the profile image */
        top: 30%;
        left: 50%;
    }

    .icon-block-personell {
        margin-top: 20px;
    }

    .aboutus-container {

        margin: 0px;
        padding: 0px;

    }

    .aboutus {

        margin: 0px;
        padding: 0px;

    }
    .carousel-inner {
        box-shadow: none;
    }
    .carousel-inner {
        padding: 0;
    }

}

@media (max-width: 425px) {
    .aboutus-container {
        padding-left: 90px;
    }
}

@media (max-width: 375px) {
    .aboutus-container {
        padding-left: 60px;
    }
}